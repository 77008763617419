import React from "react";
import banner from "./assets/banner.jpg";

const HomePage = () => {
  return (
    <div className="child">
      <div className="content">
        <h1>PstMobile</h1>
        <p style={{ padding: "30px" }}>Convenience at your fingertips. The PstMobile APP enables purchasing of prepaid products and services, such as airtime, electricity, water and make payment for DSTV, GOTV, BoxOffice, postpaid water bills all from one app. Look out for more value-added services coming soon! Anyone who downloads and uses the PstMobile app.</p>
        <p>If you have any questions, please contact us at 083 160 0013 or at email:info@pstmobile.com.na</p>
      </div>
      <img style={{ width: "100%", marginTop: "30px" }} src={banner} alt="Banner" />
    </div>
  );
};

export default HomePage;
