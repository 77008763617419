import React from "react";

const PrivacyPage = () => {
  return (
    <div className="child" style={{ padding: "30px" }}>
      <h1>PROTECTING YOUR PRIVACY</h1>
      <p style={{ padding: "20px 0" }}>Last updated: 24 February 2023</p>
      <p style={{ padding: "20px 0" }}>Protecting your privacy is important to us. We comply with the relevant legislation; you trust us with your personal information, and we respect your privacy. Our privacy notice will explain why and how we collect, use, and store your personal information. If you have any questions, please contact us at 083 160 0013 or at email:info@pstmobile.com.na</p>
      <p style={{ padding: "20px 0", fontWeight: "500" }}>
        Writing in bold parts contains information about provisions that have special consequences for you. These parts are only intended to bring such provisions to your attention, and, where necessary, to explain their nature and effect. Where explanations are given, they may be contained in a box. Such boxed explanations are aids to understanding only and are not provisions themselves. They do not limit the meaning or application of the provisions and do not apply only to the situations and
        examples described in the boxes or only to similar situations or examples.
      </p>
      <p style={{ padding: "20px 0", fontWeight: "500" }}>Your consent to the processing of your personal information</p>
      <p style={{ padding: "20px 0" }}>We want you to understand who you are sharing your information with, what kind of information we are collecting, and how we use the information.</p>
      <p style={{ padding: "20px 0" }}>In your day-to-day dealings with PstMobile #Business, we obtain information about you. We want you to know exactly what that information is and what we do with it. After all, trust is nothing without transparency.</p>
      <p style={{ padding: "20px 0" }}>We need your information to deliver the right product, at the right time and in the right place.</p>
      <p style={{ padding: "20px 0" }}>We ask for your information to make sure that we can offer the right product at the right time and in the right place.</p>
      <p style={{ padding: "20px 0" }}>The Protection of Personal Information Act protects you.</p>
      <p style={{ padding: "20px 0" }}>The Protection of Personal Information Act (POPI) is aimed at protecting your personal information and prescribes what we can and cannot do with it. POPI created an Information Regulator that checks that companies manage personal information in a responsible manner that respects your privacy.</p>
      <p style={{ padding: "20px 0" }}>This privacy notice is part of our agreement with you.</p>
      <p style={{ padding: "20px 0" }}>This privacy notice forms part of our agreement with you. You should read it along with the terms and conditions that apply. These terms and conditions can be accessed on our website.</p>
      <p style={{ padding: "20px 0" }}>This notice may change from time to time.</p>
      <p style={{ padding: "20px 0" }}>From time- to -time we may have to amend this notice to accommodate changes in our business or services or if legal requirements change.</p>
      <p style={{ padding: "20px 0" }}>What information we collect</p>
      <p style={{ padding: "20px 0" }}>Depending on what you apply for we require information such as your name, contact numbers, ID or passport number. We collect this information to process your application so you can have a customer record with us. This is how we provide all the services as set out in the terms and conditions for the products for sale.</p>
    </div>
  );
};

export default PrivacyPage;
