import React from "react";
import logo from "./assets/logo.png";

const Navbar = () => {
  return (
    <div className="navbar">
      <img src={logo} alt="PstMobile" />
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/services">Services</a>
        </li>
        <li>
          <a href="/privacy">Privacy policy</a>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
