import React from "react";
import advert from "./assets/PstMobile Short Ad.mp4";

const ServicesPage = () => {
  return (
    <div className="child" style={{ padding: "30px" }}>
      <video autoPlay="true" loop style={{ margin: "0 auto", display: "block", width: "70%" }} src={advert}></video>
    </div>
  );
};

export default ServicesPage;
